import { React } from 'react';
import { Stack, Text } from '@fluentui/react';

import stethoscopeImg from 'assets/stethoscope.svg';
import hospitalImg from 'assets/hospital.svg';
import medicalReportImg from 'assets/medicalReport.svg';
import getStyles from './AboutUs.styles';
import { useFluentTheme } from '@theme/index';

export default function AboutUs() {
  const theme = useFluentTheme();
  const styles = getStyles(theme);
  const medicalInfo = [
    {
      img: stethoscopeImg,
      mainText: '80+',
      mainTextColor: theme.palette.blue5,
      subText: 'Health Provider Shortage Areas',
    },
    {
      img: hospitalImg,
      mainText: '13,214',
      mainTextColor: theme.palette.yellow1,
      subText: 'State Health Administrators',
    },
    {
      img: medicalReportImg,
      mainText: '$32million',
      mainTextColor: theme.palette.red8,
      subText: 'Annual MDHS Budget',
    },
  ];

  const renderMedicalInfo = () =>
    medicalInfo.map(({ img, mainText, mainTextColor, subText }) => (
      <Stack horizontal>
        <Stack horizontalAlign='end' verticalAlign='center'>
          <img src={img} className={styles.smallInfoImg} />
        </Stack>

        <Stack
          horizontalAlign='start'
          verticalAlign='center'
          className={styles.medicalIndividualItemTextWrap}
        >
          <Text
            variant='xxLarge'
            className={styles.medicalIndividualItemMainText}
            style={{
              color: mainTextColor,
            }}
          >
            {mainText}
          </Text>
          <Text variant='xLarge' className={styles.medicalIndividualItemSubText}>
            {subText}
          </Text>
        </Stack>
      </Stack>
    ));

  return (
    <Stack horizontalAlign='center' vertical styles={{ root: [{ width: '100%' }] }}>
      <Text variant='xLarge' className={styles.aboutUsMetaText}>
        Our cutting-edge AI-powered telehealth platform redefines healthcare delivery by seamlessly
        integrating digital solutions for payors, providers, and patients. For payors, it&#39;s an
        invaluable tool for cost optimization through predictive analytics, reducing unnecessary
        expenses while ensuring proactive care management. Providers experience streamlined
        workflows and enhanced diagnostics with AI-driven decision support, empowering them to
        deliver precise diagnoses and personalized treatment plans using digital tools. Patients
        enjoy unparalleled accessibility with virtual consultations, remote monitoring capabilities,
        and timely interventions, granting them greater control over their health journey through a
        user-friendly digital interface. Our platform encapsulates a comprehensive approach,
        fostering collaboration among stakeholders and offering a superior healthcare experience
        through innovative digital technologies.
      </Text>
    </Stack>
  );
}
