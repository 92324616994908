import { ITheme } from "@theme/foundations";
import { mergeStyleSets } from "@fluentui/react";

const getStyles = (theme: ITheme) =>
    mergeStyleSets({
        mainWrapper: {
            '@media(max-width: 992px)': {
                flexDirection: 'column',
            }
        },
        mainSection: {
            justifyContent: 'center',
            '@media(max-width: 992px)': {
                flexDirection: 'column',
                justifyContent: 'space-between',
            }
        },
        imgSection: {
            '@media(max-width: 992px)': {
                marginTop: theme.spacing.s5
            }
        },

        aboutUsMainImg: {
            width: "50%",
            maxWidth: '400px',
            '@media(max-width: 992px)': {
                width: "100%",
            },
            '> img': {
                borderRadius: '16px',
            }
        },
        aboutUsMainContent: {
            width: "50%",
            marginLeft: theme.spacing.s7,
            '@media(max-width: 992px)': {
                width: "100%",
                marginTop: theme.spacing.s5,
                marginLeft: '0',
            }
        },
        aboutUsText: {
            '@media(max-width: 992px)': {
                fontSize: theme.fontSizes.xl,
            }
        },
        aboutUsMetaText: {
            textAlign: "left",
            color: theme.palette.gray1,
            marginTop: `${theme.spacing.s4} !important`,
            fontWeight: 400,
            '@media(max-width: 992px)': {
                marginTop: `${theme.spacing.s3} !important`,
                fontSize: theme.fontSizes.m,
            }
        },
        informationSection: {
            marginTop: '100px',
            padding: theme.spacing.s6,
            backgroundColor: theme.palette.white,
            '@media(max-width: 992px)': {
                marginTop: '60px',
            }
        },

        smallInfoImg: {
            borderRadius: '16px',
            width: 64,
            height: 64,
            '@media (max-width: 992px)': {
                width: 48,
                height: 48
            }
        },
        medicalIndividualItemTextWrap: {
            marginLeft: theme.spacing.s3
        },
        medicalIndividualItemMainText: {
            '@media(max-width: 992px)': {
                fontSize: theme.fontSizes.l,
            }
        },
        medicalIndividualItemSubText: {
            textAlign: "left",
            marginTop: theme.spacing.s1,
            '@media(max-width: 992px)': {
                fontSize: theme.fontSizes.m,
                marginTop: 0,
            }
        }
    });

export default getStyles;